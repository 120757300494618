<template>
    <div class="">
        <div class="">

            <!-- /.content-header -->
            <!-- Main content -->
            <section class="content">
                <div class="container-fluid">
                    <!-- Main row -->
                    <div class="row">
                        <section class="col-lg-12 connectedSortable table-area mt-3 pr-0 pl-0 mb-3">
                            <div class="card">
                                <div class="card-body table-responsive table-head">
                                    <div class="row bor-bot">
                                    <div class="col-md-8 pl-0 mb-3">
                                       <h1 class="m-0 text-dark pt-2 pb-2">Member Registration Reports</h1>
                                    </div>
                                    <div class="col-md-4">  
                                        <div class="text-right pb-3">
                                        <ol class="breadcrumb float-sm-right">
                                        <li class="breadcrumb-item"><a href="/admin/dashboard">Home</a></li>
                                        <li class="breadcrumb-item"><Breadcrumbs/></li>
                                        </ol>
                                        </div>   
                                    </div>
                                  </div>
                                    <div class="table-responsive mt-3" id="member_reg_report_table">
                                        <div class="data-tablebutn pb-3">
                                            <b-button class="btn-primary btn-darkblue btn mr-1" :class="{ 'filter-applied': filtersApplied }" v-b-modal.modal-member-report>
                                                <i class="fas fa-sliders-h"></i> Filters
                                            </b-button>
                                            <b-button class="btn-primary btn-darkblue btn" @click="refreshList" v-if="filtersApplied">
                                                <i class="fas fa-redo"></i> Reset
                                            </b-button>
                                            <b-button class="ml-1 btn-primary btn-darkblue btn" @click="exportCsv" :disabled="exportProgress">
                                                <i class="fas" :class="[ exportProgress ? 'fa-circle-notch fa-spin' : 'fa-file-download' ]"></i> Export
                                            </b-button>
                                        </div>
                                      <div class="search-box">
                                        <input type="text" class="input-field form-control " style="width:100%; max-width:234px" v-model="searchValue" name="general_search" id="general_search" @keyup="queryForKeywords">
                                        <i class="fas fa-search" style="position:absolute"></i>
                                      </div>
                                        <table class="table table-sm table-bordered table-hover" id="list_table">
                                            <thead class="thead-dark">
                                            <tr>
                                                <th>Member Id</th>
                                                <th>Name</th>
                                                <th>E-Mail</th>
                                                <th>State</th>
                                                <th>DOB</th>
                                                <th>Membership</th>
                                                <th>Effective From</th>
                                                <th>Effective To</th>
                                                <th>Waiver Signed On</th>
                                                <th>Waiver Expiration On</th>
                                                <th class="none">Status</th>
                                                <th class="none">Transaction Id</th>
                                                <th class="none">Date Paid</th>
                                                <th class="none">Team Id</th>
                                                <th class="none">Team Name</th>
                                                <th class="none">League</th>
                                                <th class="none">Age Division</th>
                                                <th class="none">Recruiting</th>
                                                <th class="none">Citizenship</th>
                                                <th class="none">Graduation Year</th>
                                                <th class="none">Coupon Code</th>
                                                <th class="none">Action</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <!-- Data will be placed here -->
                                            <tr>
                                                <td colspan="9">&nbsp;</td>
                                            </tr>
                                            <tr>
                                                <td colspan="9">&nbsp;</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                        <b-modal id="modal-member-report" v-bind:hide-footer="true" ref="modal-member-report" size="lg"  title="Advanced Search">
                                            <form ref="form" method="post" @submit.prevent="advancedSearch()">
                                                <div class="row">
<!--                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label class="form-label">Type</label>
                                                            <select class="form-control">
                                                                <option>Select</option>
                                                                <option>3-Event - AWS</option>
                                                                <option>Collegiate - NCW</option>
                                                                <option>Kneeboard - AKA</option>
                                                            </select>
                                                        </div>
                                                    </div>-->
<!--                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label class="form-label">Name</label>
                                                            <input type="text" class="form-control">
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label class="form-label">Confirmation Code</label>
                                                            <input type="text" class="form-control">
                                                        </div>
                                                    </div>-->

                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label class="form-label">Member Id</label>
                                                            <input type="text" id="confirmation_code" class="form-control" v-model="searchForm.confirmation_code">
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label class="form-label">First Name</label>
                                                            <input type="text" id="first_name" class="form-control" v-model="searchForm.first_name">
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label class="form-label">Last Name</label>
                                                            <input type="text" id="last_name" class="form-control" v-model="searchForm.last_name">
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label class="form-label">Email</label>
                                                            <input type="text" id="email" class="form-control" v-model="searchForm.email">
                                                        </div>
                                                    </div>

                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label class="form-label">Registration From Date</label>
                                                            <div class="input-group birthdate">
                                                                <div class="birthdaypicker">
                                                                    <date-pick
                                                                        v-model="searchForm.registration_start_date"
                                                                        :format="'MM/DD/YYYY'"
                                                                        :displayFormat="'MM/DD/YYYY'"
                                                                        v-mask="'##/##/####'"
                                                                        :parseDate="parseDatePick"
                                                                    ></date-pick>
                                                                </div>
                                                            </div>
                                                            <input id="registration_start_date" type="hidden" class="form-control" :value="registration_start_date_1">
                                                            <div class="text-sm text-danger">{{ errors.registration_start_date }}</div>
                                                        </div>
                                                    </div>
                                                    
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label class="form-label">Registration To Date</label>
                                                            <div class="input-group birthdate">
                                                                <div class="birthdaypicker">
                                                                    <date-pick
                                                                        v-model="searchForm.registration_end_date"
                                                                        :format="'MM/DD/YYYY'"
                                                                        :displayFormat="'MM/DD/YYYY'"
                                                                        v-mask="'##/##/####'"
                                                                        :parseDate="parseDatePick"
                                                                    ></date-pick>
                                                                </div>
                                                            </div>
                                                            <input id="registration_end_date" type="hidden" class="form-control" :value="registration_end_date_1">
                                                            <div class="text-sm text-danger">{{ errors.registration_end_date }}</div>
                                                        </div>
                                                    </div>
                                                    
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label class="form-label">Date of Birth</label>
                                                            <div class="input-group birthdate">
                                                                <div class="birthdaypicker">
                                                                    <date-pick
                                                                        v-model="searchForm.registration_dob"
                                                                        :format="'MM/DD/YYYY'"
                                                                        :displayFormat="'MM/DD/YYYY'"
                                                                        v-mask="'##/##/####'"
                                                                        :parseDate="parseDatePick"
                                                                    ></date-pick>
                                                                </div>
                                                            </div>
                                                            <input id="registration_dob" type="hidden" class="form-control" :value="registration_dob_1">
                                                            <div class="text-sm text-danger">{{ errors.registration_dob }}</div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label class="form-label">Gender</label>
                                                            <select id="gender" class="form-control" v-model="searchForm.gender">
                                                                <option value="">Select</option>
                                                                <option value="Male">Male</option>
                                                                <option value="Female">Female</option>
                                                                <option value="Other">Other</option>
                                                                <option value="Prefer not to say">Prefer not to say</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6" v-if="subdomain=='alliancefastpitch'">
                                                        <div class="form-group">
                                                            <label class="form-label">Registration Year</label>
                                                            <select id="season" class="form-control" v-model="searchForm.season">
                                                                <option selected="selected" value="">Select</option>
                                                              <option v-for="(season, id) in seasons.slice().sort((a, b) => b.id - a.id).reverse()" :value="season.id" :key="id">{{ season.text }}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6" v-else>
                                                        <div class="form-group">
                                                            <label class="form-label">Registration Year</label>
                                                            <select id="year1" class="form-control" v-model="searchForm.year">
                                                                <option selected="selected" value="">Select</option>
                                                                <template v-for="year in years">
                                                                    <option v-if="year >= 2018" :value="year" :key="'year' + year">{{ year }}</option>
                                                                </template>
                                                            </select>
                                                        </div>
                                                    </div>
                                                     <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label class="form-label">Membership</label>
                                                          <select id="membership_id" name="membership_id" class="form-control" v-model="searchForm.membership_id">
                                                            <option value="">Select</option>
                                                            <option v-for="(id, val) in membershipData" :value="val" :key="val">{{ id }}</option>
                                                          </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label class="form-label">Status</label>
                                                            <!-- <input type="text" class="form-control"> -->
                                                            <select id="status" name="status" class="form-control" v-model="searchForm.status">
                                                                <option value="">Select</option>
                                                                <template v-for="(id, val) in membershipStatus">
                                                                    <option v-if="val > 0" :value="val" :key="val">{{ id }}</option>
                                                                </template>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label class="form-label">Transaction Id</label>
                                                            <input type="text" id="transaction_id" class="form-control" v-model="searchForm.transaction_id">
                                                        </div>
                                                    </div>

                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label for="transaction_start_date" class="form-label">Transaction From Date</label>
                                                            <div class="input-group birthdate">
                                                                <div class="birthdaypicker">
                                                                    <date-pick
                                                                        v-model="searchForm.transaction_start_date"
                                                                        :format="'MM/DD/YYYY'"
                                                                        :displayFormat="'MM/DD/YYYY'"
                                                                        v-mask="'##/##/####'"
                                                                        :parseDate="parseDatePick"
                                                                    ></date-pick>
                                                                </div>
                                                            </div>
                                                            <input id="transaction_start_date" type="hidden" class="form-control" :value="transaction_start_date_1">
                                                            <div class="text-sm text-danger">{{ errors.transaction_start_date }}</div>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label for="transaction_end_date" class="form-label">Transaction To Date</label>
                                                            <div class="input-group birthdate">
                                                                <div class="birthdaypicker">
                                                                    <date-pick
                                                                        v-model="searchForm.transaction_end_date"
                                                                        :format="'MM/DD/YYYY'"
                                                                        :displayFormat="'MM/DD/YYYY'"
                                                                        v-mask="'##/##/####'"
                                                                        :parseDate="parseDatePick"
                                                                    ></date-pick>
                                                                </div>
                                                            </div>
                                                            <input id="transaction_end_date" type="hidden" class="form-control" :value="transaction_end_date_1">
                                                            <div class="text-sm text-danger">{{ errors.transaction_end_date }}</div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6" v-if="subdomain == 'waterski' ">
                                                      <div class="form-group">
                                                        <label class="form-label">Region</label>
                                                        <input type="text" id="region_name" class="form-control" v-model="searchForm.region_name">
                                                      </div>
                                                    </div>
                                                    <div class="col-md-6" v-if="getSiteProps('group_model.visible')">
                                                      <div class="form-group">
                                                        <label class="form-label">Groups</label>
                                                        <Select2 v-model="searchForm.groups" name="groups" id="edit-groups" :options="groupList" :settings="{ multiple: true, placeholder: 'Click to select one or multiple'}" />
                                                      </div>
                                                    </div>
                                                    <div class="col-md-6" v-if="getSiteProps('group_model.visible')">
                                                      <div class="form-group">
                                                        <label class="form-label">Tags</label>
                                                        <Select2 v-model="searchForm.tags" name="tags" id="tags" :options="tags" :settings="{ multiple: true, placeholder: 'Click to select one or multiple'}" />
                                                      </div>
                                                    </div>
                                                    <div class="col-md-6" v-if="getSiteProps('volunteering.visibility')">
                                                      <div class="form-group">
                                                        <label class="form-label">Volunteering</label>
                                                        <select class="form-control" id="volunteering_roles" v-model="searchForm.volunteering_roles">
                                                            <option value="">Select a Role</option>
                                                            <option v-for="(role, id) in roles" :value="role.id" :key="id">{{ role.text }}</option>
                                                        </select>
                                                      </div>
                                                    </div>
                                                    <div class="col-md-6" v-if="getSiteProps('league.visibility')">
                                                      <div class="form-group">
                                                        <label class="form-label">League</label>
                                                        <select class="form-control" id="league" v-model="searchForm.league">
                                                            <option value="">Select League</option>
                                                            <option v-for="(league, id) in leagues" :value="league.id" :key="id">{{ league.name }}</option>
                                                        </select>
                                                      </div>
                                                    </div>
                                                    <div class="col-md-6" v-if="this.getSiteProps('memberRegistration.team.fields.club_info.age_division')">
                                                      <div class="form-group">
                                                        <label class="form-label">Age Division</label>
                                                        <select class="form-control" id="age_division" v-model="searchForm.age_division">
                                                            <option value="">Select Age Division</option>
                                                            <option v-for="(discipline, id) in sportDisciplinesSelect2" :value="discipline.id" :key="id">{{ discipline.text }}</option>
                                                        </select>
                                                      </div>
                                                    </div>
                                                    <div v-if="subdomain == 'alliancefastpitch'" class="col-md-6">
                                                        <div class="form-group">
                                                        <label for="team_id" class="form-label">Team</label>
                                                        <select class="form-control" id="team_id" v-model="searchForm.team_id">
                                                            <option value="">Select Team</option>
                                                            <option v-for="(TeamData,key) in teams" :key="key" :value="TeamData.id">
                                                            {{ TeamData.text }}
                                                            </option>
                                                        </select>
                                                        <span class="text-sm text-danger"></span>
                                                        </div>
                                                    </div> 
                                                    <div v-if="false" class="col-md-6">
                                                        <div class="form-group">
                                                            <label class="form-label">Recruiting</label>
                                                            <select id="member_ncsa" class="form-control" v-model="searchForm.member_ncsa">
                                                                <option value="">Select</option>
                                                                <option value="Yes">Yes</option>
                                                                <option value="No">No</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div v-if="subdomain == 'alliancefastpitch'" class="col-md-6">
                                                      <div class="form-group">
                                                        <label class="form-label">Citizenship</label>
                                                        <select id="citizenship" class="form-control" v-model="searchForm.citizenship">
                                                          <option
                                                              v-for="listOfCountry in listOfCountries"
                                                              :key="listOfCountry.country">
                                                            {{ listOfCountry.country }}
                                                          </option>
                                                        </select>
                                                      </div>
                                                    </div>
                                                    <div v-if="subdomain == 'alliancefastpitch'" class="col-md-6">
                                                        <div class="form-group">
                                                            <label class="form-label">Graduation Year</label>
                                                             <select class="form-select form-control" id="graduation_year" v-model="searchForm.graduation_year">
                                                        <option value="">Select Graduation Year</option>
                                                        <option v-for="year in graduation_year" :value="year" :key="year">{{ year }}</option>
                                                      </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                      <div class="form-group">
                                                        <label class="form-label">Coupon Code</label>
                                                        <Select2 v-model="searchForm.coupon_code" name="coupon_code" id="coupon_code" :options="couponcode" :settings="{ multiple: true, placeholder: 'Click to select one or multiple'}" />
                                                      </div>
                                                    </div>
                                                  <div class="col-md-12 modal-searchbtn text-center">
                                                        <div class="form-group">
                                                            <button type="submit" class="btn btn-primary btn-blue">{{ button.SearchButton }}</button>
                                                        </div>
                                                        <div class="text-sm text-danger">{{ errors.not_found }}</div>
                                                    </div>
                                                </div>
                                            </form>
                                        </b-modal>
                                    </div>
                                    </div>
                        </section>
                    </div>
                    <!-- /.row (main row) -->
                </div><!-- /.container-fluid -->
            </section>
        </div>
        <!-- /.content -->
    </div>
</template>

<script>
  import $ from "jquery";
//   import DownloadExcel from "vue-json-excel";
import "datatables.net-responsive-dt/js/responsive.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-responsive-dt/css/responsive.dataTables.min.css";

  import moment from 'moment';

  import DatePick from "vue-date-pick";
  import axios from 'axios';

  const validateName = name => {
      if (name.length || name != "") {
          return { valid: true, error: null };
      }
      return { valid: false, error: null };
  };

  let cachedData = {};
  export default {
      components: { DatePick },
      el:'#memreport',
    data() {
      return {
          searchValue:'',
          exportProgress: false,
          filtersApplied: false,
          loaded: false,
          perPage: 10,
          currentPage: 1,
          fields: [
              {
                  key: 'member_id',
                  label: 'Member Id',
                  sortable: true
              },
              {
                  key: 'first_name',
                  sortable: true
              },
              {
                  key: 'last_name',
                  sortable: true
              },
              {
                  key: 'email',
                  // label: 'Email',
                  sortable: true,
              },
              {
                  key: 'dob',
                  label: 'DOB',
                  sortable: false,
              },
              {
                  key: 'gender',
                  label: 'Gender',
                  sortable: false,
              },
              {
                  key: 'updated_at',
                  label: 'Registered',
                  sortable: true,
                  render: (data)=>{return this.formatDate(data)}
              },
              {
                  key: 'status',
                  label :'Status',
                  sortable: true,
              },
              {
                  key: 'trans_id',
                  sortable: true,
              },
              {
                  key: 'actions',
                  // label: 'Email',
                  sortable: false,
              },
          ],
          items: [],
          viewEntryOptions: [
              { value: 5, text: '5' },
              { value: 10, text: '10' },
              { value: 20, text: '20' },
              { value: 20, text: '50' },
              { value: 20, text: '100' },
          ],
          filter: null,
          filterOn: [],
          infoModal: {
              id: 'info-modal',
              title: '',
              content: ''
          },
          couponcode:[],
          searchForm: {
            confirmation_code:'',
            first_name: '',
            last_name: '',
            email: '',
            registration_start_date: '',
            registration_end_date: '',
            transaction_start_date: '',
            transaction_end_date: '',
            registration_dob:'',
            gender: '',
            year: '',
            membership_id: '',
            status: '',
            transaction_id: '',
            groups:[],
            tags:'',
            volunteering_roles:'',
            league:'',
            age_division:'',
            member_ncsa:'',
            season:'',
            citizenship:'',
            coupon_code:'',
            graduation_year:''
          },
           minYear: 1900, // Minimum year allowed
           maxYear: new Date().getFullYear(), // Maximum year allowed
          button: {
              SearchButton: 'Search'
          },
          errors: {},
          table: {
 
          },
          term: '',
          groupList:[],
          GroupModel:'',
          tags:[],
          volunteering:false,
          roles:[],
          leagues:[],
          teams: [],
          team_id:'',
          seasons:[],
          graduation_year:[]
        }
     },
      methods: {
        queryForKeywords(event) {
          const value = event.target.value
          this.keywords = value
          if(!isNaN(value) && value != '' && value.length == 9 & this.subdomain == 'alliancefastpitch'){
            this.searchForm.confirmation_code = value;
            this.advancedSearch();
          }else{
            this.searchForm.confirmation_code = '';
            this.table.search(value).draw();
          }
        },

        formatDate(value) {
          var date = moment(value, "YYYY-MM-DD hh:mm:ss");
          if (date.isValid()) {
            return date.format('MM/DD/YYYY');
          } else {
            return 'N/A';
          }
        },
        formatLongDate(value1) {
          var date = moment(value1);
          if (date.isValid()) {
            return date.format('MM/DD/YYYY');
          } else {
            return 'N/A';
          }
        },
          onFiltered(filteredItems) {
              // Trigger pagination to update the number of buttons/pages due to filtering
              this.totalRows = filteredItems.length
              this.currentPage = 1
          },
          viewRow(item, index, button){
              this.infoModal.title = item.first_name + ' ' + item.last_name;
              this.infoModal.content = item;
              this.$root.$emit('bv::show::modal', this.infoModal.id, button);
          },
          resetInfoModal() {
              this.infoModal.title = ''
              this.infoModal.content = ''
          },
          editRow(id){
              console.log('Editing row...' + id);
          },
          deleteRow(id){
              console.log('Deleting row...' + id);
          },
          loadData: function () {
              axios.post(process.env.VUE_APP_URL + 'api/reports/registration/Individual', this.searchForm)
                  .then((response) => {
                      cachedData = response.data.registrations;
                      this.items = cachedData;
                      this.loaded = true;
                      this.button.SearchButton = 'Search';
                      this.items = cachedData;
                      this.loaded = true;
                      if(this.loaded == true) {
                          this.$refs['modal-member-report'].hide();
                      }
                      return true;
                  })
                  .catch(error => this.errors = error.response.data);
          },
          advancedSearch() {
              if(!this.validate()) {
                  return;
              }
              this.button.SearchButton = 'Searching...';
              // Prevent modal from closing
              //bvModalEvt.preventDefault()
              this.loaded = false;
              // Trigger submit handler
              this.table.draw();
              this.filtersApplied = true;
              this.$refs['modal-member-report'].hide();
              this.button.SearchButton = 'Search';
          },
          // Custom Validation Rules
          validate() {
              this.errors = {}
              this.valid =  true;
              const validStartDate = validateName(this.searchForm.registration_start_date);
              this.errors.registration_start_date = validStartDate.error;

              const validEndDate = validateName(this.searchForm.registration_end_date);
              this.errors.registration_end_date = validEndDate.error;
              
              var dateValid = false;
                if(validStartDate.valid && !this.validateDate(this.searchForm.registration_start_date)){
                    dateValid = true;
                    this.errors.registration_start_date = "Invalid Date";
                }
                if(validEndDate.valid && !this.validateDate(this.searchForm.registration_end_date)){
                    dateValid = true;
                    this.errors.registration_end_date = "Invalid Date";
                }

                if(dateValid == false){
                    if((validStartDate.valid && validEndDate.valid) && (moment(this.searchForm.registration_end_date).isBefore(this.searchForm.registration_start_date))) {
                        this.errors.registration_end_date = 'End date must be greater than Start date!';
                        return this.valid =  false;
                    } else {
                        this.valid = true;
                    }
                } else {
                    this.valid = false;
                }

                const validTStartDate = validateName(this.searchForm.transaction_start_date);
                this.errors.transaction_start_date = validTStartDate.error;

                const validTEndDate = validateName(this.searchForm.transaction_end_date);
                this.errors.transaction_end_date = validTEndDate.error;
                
                dateValid = false;
                if(validTStartDate.valid && !this.validateDate(this.searchForm.transaction_start_date)){
                    dateValid = true;
                    this.errors.transaction_start_date = "Invalid Date";
                }
                if(validTEndDate.valid && !this.validateDate(this.searchForm.transaction_end_date)){
                    dateValid = true;
                    this.errors.transaction_end_date = "Invalid Date";
                }

                if(dateValid == false){
                    if((validTStartDate.valid && validTEndDate.valid) && (moment(this.searchForm.transaction_end_date).isBefore(this.searchForm.transaction_start_date))) {
                        this.errors.transaction_end_date = 'End date must be greater than Start date!';
                        return this.valid =  false;
                    } else {
                        this.valid = true;
                    }
                } else {
                    this.valid = false;
                }

                if(this.searchForm.registration_dob != "" && !this.validateDate(this.searchForm.registration_dob)){
                    this.errors.registration_dob = "Invalid Date";
                    this.valid = false;
                }

              return this.valid;
          },
        getMembership(){
          axios.get(this.basePath + "api/getMemberships/individual")
              .then((response) => {
                this.membershipData = response.data.data;
                if(this.subdomain == 'shooting'){
                    delete this.membershipData[4];
                }
              })
              .catch(error=>{
                console.log("error getting Membership info")
              })
        },
        getCoupon() {
          axios
              .get(this.basePath + "api/coupons/index")
              .then((response) => {
                let coupon = response.data.couponList;
                let coupon_codes = Object.keys(coupon).sort();
                coupon_codes.forEach(code => {
                  this.couponcode.push({ id: code, text: code });

                });
              })
              .catch(error => {this.errors = error.response.data.couponList});
        },
        refreshList(event){
            this.searchForm = {
                confirmation_code:'',
                first_name: '',
                last_name: '',
                email: '',
                registration_start_date: '',
                registration_end_date: '',
                transaction_start_date: '',
                transaction_end_date: '',
                registration_dob:'',
                gender: '',
                year: '',
                membership_id: '',
                status: '',
                transaction_id: '',
                region_name:'',
                groups:[],
                tags:[],
                volunteering_roles:'',
                league:'',
                age_division:'',
                member_ncsa:'',
                season:'',
                citizenship:'',
                coupon_code:'',
                graduation_year:''
            };
            // this.table.clear().draw();
            this.table.ajax.reload();
            this.filtersApplied = false;
        },
        exportCsv(){
            var self = this;
            $.ajax({
                url: process.env.VUE_APP_URL + "api/reports/export/member-registrations/Individual",
                type: 'post',
                "data": this.table.ajax.params(),
                beforeSend: function(){
                    self.exportProgress = true;
                },
                "success": function(res, status, xhr) {
                    self.exportProgress = false;
                    if(res.file_url != undefined) window.open(res.file_url, '_blank');
                },
                error: function(err){
                    console.log(err);
                    self.exportProgress = false;
                }
            });
        },
        Search(event) {
          this.searchValue = this.$route.query.term;
          if(this.searchValue !== undefined) {
            this.searchValue = $("#globalSearch").val();
            const dataTable = $("#list_table").DataTable();
            dataTable.search(this.searchValue).draw();
          }
        },
        getGroupList()
        {
            axios.get(this.basePath+'api/admin/group/list',{ headers:this.adminHeaders })
            .then((res) => {
                let groupList_id = res.data.data;                 
                let group_list = Object.keys(groupList_id);
                
                if(group_list.length ){        
                    group_list.forEach(id => { 
                        this.groupList.push({ id: groupList_id[id].id, text: groupList_id[id].name });
                    });     
                }       
            })
            .catch((error) => {
                console.log('Error:'+ error);               
            }).finally(() => {
            });
        },
        getTags()
        {
            axios.get(this.basePath+'api/admin/group/tags',{ headers:this.adminHeaders })
            .then((res) => {
                this.tags = res.data.data;  
            })
            .catch((error) => {
                console.log('Error:'+ error);               
            }).finally(() => {
            });
        },
        getVolunteeringRoles()
        {
            axios.get(this.basePath+'api/admin/volunteering/roles', { headers: this.adminHeaders })
                .then((res) => { 
                    this.roles = res.data.data;
                })
                .catch((error) => {
                    console.log('Error:'+ error);               
                    return false;            
                }).finally(() => {
                    
                }
            );  
        },
        getLeague()
        {
            axios.get(this.basePath+'api/league', { headers: this.adminHeaders })
                .then((res) => { 
                    this.leagues = res.data.data;
                })
                .catch((error) => {
                    console.log('Error:'+ error);               
                    return false;            
                }).finally(() => {
                    
                }
            );  
        },
        getTeam()
        {
            axios.get(this.basePath+'api/get_teams', { headers: this.adminHeaders })
                .then((res) => { 
                    this.teams = res.data.data;
                })
                .catch((error) => {
                    console.log('Error:'+ error);               
                    return false;            
                }).finally(() => {
                    
                }
            );  
        },
        getSeason()
        {
            axios.get(this.basePath+'api/get_season', { headers: this.adminHeaders })
                .then((res) => { 
                    this.seasons = res.data.data;
                })
                .catch((error) => {
                    console.log('Error:'+ error);               
                    return false;            
                }).finally(() => {
                    
                }
            );  
        },
      },
      mounted() {

    const year = new Date().getFullYear();
    //   this.yearArr = Array.from({length: 12}, (value, index) => year + index);

      const yrs = year+20;
      const twoDigitYear = yrs.toString().substr(-2);
      const lengthOfYear = parseInt(20) + parseInt(twoDigitYear);
      this.graduation_year = Array.from({length: lengthOfYear}, (value, index) => yrs - index);

        const retry = setInterval(() => {
            this.volunteering = this.getSiteProps('volunteering.visibility');
            if(this.volunteering)
            {
                this.getVolunteeringRoles();
            }
            if(this.volunteering != undefined) clearInterval(retry);
        }, 200);

        const retry2 = setInterval(() => {
        this.GroupModel = this.getSiteProps('group_model.visible');  
        if(this.GroupModel)
        {          
          this.getGroupList();
          this.getTags();
        }          
        if(this.GroupModel != undefined) clearInterval(retry2);
      }, 200);

      const retry3 = setInterval(() => {
        this.league = this.getSiteProps('league.visibility');  
        if(this.league)
        {          
          this.getLeague();
          this.getDiscipline();
          this.getSeason();
        }          
        if(this.league != undefined) clearInterval(retry3);
      }, 200);

        // this.searchForm.year = (this.subdomain == 'shooting') ? new Date().getFullYear() : '';
        this.getMembership();
        this.getMembershipStatus();
        this.getTeam();
        this.getCoupon();


        //   $.ajaxSetup({
        //       headers: {
        //           'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        //       }
        //   });
        //   var filters = this.searchForm;
            var self = this;
           this.table = $("#list_table").DataTable({
            dom: "lfrtip",
            processing: true,
            serverSide: true,
            bFilter: true,
            responsive: true,
            ajax: {
                url: process.env.VUE_APP_URL + "api/member-reports",
                type: 'post',
                data: function (d) {
                    d.confirmation_code = $('#confirmation_code').val() || self.searchForm.confirmation_code;
                    d.first_name = $('#first_name').val() || self.searchForm.first_name;
                    d.last_name = $('#last_name').val() || self.searchForm.last_name;
                    d.email = $('#email').val() || self.searchForm.email;
                    d.start_date = $('#registration_start_date').val() || self.searchForm.registration_start_date;
                    d.end_date = $('#registration_end_date').val() || self.searchForm.registration_end_date;
                    d.dob = $('#registration_dob').val() || self.searchForm.registration_dob;
                    d.gender = $('#gender').val() || self.searchForm.gender;
                    d.year = $("#year1").val() || self.searchForm.year;
                    d.membership_id = $('#membership_id').val() || self.searchForm.membership_id;
                    d.status = $('#status').val() || self.searchForm.status;
                    d.transaction_id = $('#transaction_id').val() || self.searchForm.transaction_id;
                    d.transaction_start_date = $("#transaction_start_date").val() || self.searchForm.transaction_start_date;
                    d.transaction_end_date = $("#transaction_end_date").val() || self.searchForm.transaction_end_date;
                    d.league = $('#league').val() || self.searchForm.league;
                    d.age_division = $('#age_division').val() || self.searchForm.age_division;
                    d.team_id = $('#team_id').val() || self.searchForm.team_id;
                    d.member_ncsa = $('#member_ncsa').val() || self.searchForm.member_ncsa;
                    d.season = $('#season').val() || self.searchForm.season;
                    d.citizenship = $('#citizenship').val() || self.searchForm.citizenship;
                    d.graduation_year = $('#graduation_year').val() || self.searchForm.graduation_year;
                    d.coupon_code = $('#coupon_code').val() || self.searchForm.coupon_code;
                }
            },
             columns: [
               { data: 'confirmation_code', name: 'confirmation_code', render: (data)=>{return data || "N/A";}},
               { data: "full_name", name: "name", render: (data)=>{return data || "N/A";} },
               { data: "email", name: "email", render: (data)=>{return '<a class="email-link" href="mailto:'+ data +'">'+ data +'</a>' || "N/A";}},
               { data: "stateCode", name: "state", orderable: true, render: (data)=>{return data || "N/A";} },
               { data: "dob", name: "dob", render: (data)=>{return this.formatDate(data) || "N/A";} },
               { data: "membership_type", name: "membership_name", render: (data)=>{return data || "N/A";} },
               { data: "start_date", name: "start_date", render: (data)=>{return this.formatDate(data)} },
               { data: "valid_thru", name: "valid_thru", render: (data)=>{return this.formatDate(data)} },
               { data: "waiver_signed_at", name: "waiver_signed_at", render: (data)=>{return data || "N/A";} },
               { data: "waiver_expired_at", name: "valid_thru", render: (data)=>{return this.formatDate(data) || "N/A";} },
               { data: "reg_status", name: "status_txt", orderable: false, render: (data)=>{return data || "N/A";} },
               { data: "transaction_id", name: "transaction_id", render: (data)=>{return data || "N/A";} },
               { data: "transaction_date", name: "transaction_date", render: (data)=>{return this.formatLongDate(data)} },
               { data: "team_member_id", name: "team_member_id", render: (data)=>{return data || "N/A"}, searchable: false },
               { data: "team_name", name: "team_name", render: (data)=>{return data || "N/A"}, searchable: false },
               { data: "member_league", name: "league_name", render: (data)=>{return data || "N/A"}, },
               { data: "age_division", name: "age_division_name", render: (data)=>{return data || "N/A"}, searchable: false },
               { data: "membership_benefits", name: "ncsa", render: (data)=>{return data || "N/A"}, visible: false },
               { data: "citizenship", name: "citizenship", render: (data)=>{return data || "N/A"} },
               { data: "graduation_year", name: "graduation_year", render: (data)=>{return data || "N/A";}, },
               { data: "coupon_code", name: "coupon_code", render: (data)=>{return data==null?"N/A":data},searchable : true },
               { data: "actions", name: "actions", orderable: false, render: (data,type,row,meta) => {
                   return `<a href="/admin/registrations/${row.member_id}"><i class="fas fa-eye"></i></a>`;
                  }
                }
             ],
            order: [[12, "desc"]]
          });
        this.Search();

        var vueRouter = this.$router;
        document.addEventListener("click", function(e){
          if(e.target && e.target.parentNode.className == "actions-click"){
            var id = e.target.parentNode.getAttribute('data-id');
            vueRouter.push('/admin/registrations/'+ id);
          }
        });
      },
      created(){
          //this.loadData();
      },

      computed: {
          rows() {
              return this.items.length
          },
        searchTerm() {
          return this.$route.query.term;
        },
          years () {
              return new Date().getFullYear() +1;
          },
          registration_start_date_1: function() {
              return this.searchForm.registration_start_date;
          },
          registration_end_date_1: function() {
              return this.searchForm.registration_end_date;
          },
          registration_dob_1: function() {
              return this.searchForm.registration_dob;
          },
          transaction_start_date_1: function () {
              return this.searchForm.transaction_start_date;
          },
          transaction_end_date_1: function(){
              return this.searchForm.transaction_end_date;
          },

      },
    watch: {
      searchTerm(newValue) {
        if (newValue !== '') {
          this.Search();
        }
      }
    }
    }

</script>
